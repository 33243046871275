import { useEffect, useState } from 'react'

const Footer = () => {
	const [copyright, setCopyright] = useState('')

	useEffect(() => {
		const currentYear = new Date().getFullYear() 
		setCopyright(`${currentYear} ChickChickGo, Inc.`)
	}, [])

	return (
		<div className='text-center p-10 mt-10 border-t dark:border-gray-700 border-gray-200'>
			<h1>{copyright}</h1>
		</div>
	)
}

export default Footer
