import ReactPlayer from 'react-player'
import { useLocation } from 'react-router-dom'
import { useResultContext } from '../context/ResultContextProvider'
import Loading from './Loading'
import { useEffect } from 'react'

const Results = () => {
	const {
		results: {
			image_results,
			// entries: news,
			results,
		},
		isLoading,
		getResults,
		searchTerm,
	} = useResultContext()

	const location = useLocation()

	useEffect(() => {
		getResults(`?query=${searchTerm}&related_keywords=true&limit=40`)
		// eslint-disable-next-line
	}, [searchTerm])

	if (isLoading) return <Loading />

	switch (location.pathname) {
		case '/search':
			return (
				<div className='flex flex-wrap justify-between space-y-6 sm:px-56'>
					{results?.results?.map(({ url, title }, index) => (
						<div key={index} className='md:w-2/5 w-full'>
							<a href={url} target='_blank' rel='noreferrer'>
								<p className='text-sm'>{url.length > 30 ? url.substring(0, 30) : url}</p>
								<p className='text-lg hover:underline dark:text-blue-300 text-blue-700'>{title}</p>
							</a>
						</div>
					))}
				</div>
			)
		case '/images':
			return (
				<div className='flex flex-wrap justify-center items-center'>
					{image_results?.map(({ image, link: [href, title] }, index) => (
						<a className='sm:p-3 p-5' href={href} key={index} target='_blank' rel='noreferrer'>
							<img src={image?.src} alt={title} loading='lazy' />
							<p className='w-36 break-words text-sm mt-2'>{title}</p>
						</a>
					))}
				</div>
			)
		case '/videos':
			return (
				<div>
					{results.map((video, index) => (
						<div className='p-2' key={index}>
							{/* NEW */}
							{/* {console.log(video)} */}
							{video?.additional_links?.[0]?.href && <ReactPlayer url={video.additional_links?.[0].href} controls width='355px' height='200px' />}
						</div>
					))}
				</div>
			)
		case '/news':
		// return (
		// 	<div className='flex flex-wrap justify-between space-y-6 sm:px-56 items-center'>
		// 		{news?.map(({ url, title, id, source }) => (
		// 			<div key={id} className='md:w-2/5 w-full'>
		// 				<a href={url} target='_blank' rel='noreferrer'>
		// 					<p className='text-lg  dark:text-blue-300 text-blue-700'>{title}</p>
		// 				</a>
		// 				<div className='flex gap-4'>
		// 					<a href={source?.href} target='_blank' rel='noreferrer'>
		// 						{source?.href}
		// 					</a>
		// 				</div>
		// 			</div>
		// 		))}
		// 	</div>
		// )
		break;
		default:
			return 'ERROR'
	}
}

export default Results
