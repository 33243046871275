import { NavLink } from 'react-router-dom'

const Link = [
	{ url: '/search', text: 'All' },
	{ url: '/news', text: 'News' },
	{ url: '/images', text: 'images' },
	{ url: '/videos', text: 'videos' },
]

const Links = () => {
	return (
		<div className='flex sm:justify-around justify-between items-center mt-4'>
			{Link.map(({ url, text },index) => (
				<NavLink to={url} className='m-2 mb-0' activeClassName='text-orange-700 border-b-2 dark:text-blue-300 border-orange-700 pb-2' key={index}>
					{text}
				</NavLink>
			))}
		</div>
	)
}

export default Links
