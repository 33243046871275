import { useDebounce } from 'use-debounce'
import { useResultContext } from '../context/ResultContextProvider'
import Links from './Links'
import { useEffect, useState } from 'react'

const Search = () => {
	const [text, setText] = useState('ddd')
	const { setsearchTerm } = useResultContext()
	const [debounceValue] = useDebounce(text, 300)

	useEffect(() => {
		if (debounceValue) setsearchTerm(debounceValue)
		// eslint-disable-next-line
	}, [debounceValue])

	return (
		<div className='relative sm:ml-48 md:ml-72 sm:-mt-10 mt-3'>
			<input value={text} type='search' className='sm:w-96 w-80  h-10 dark:bg-gray-200 border rounded-full shadow-sm p-6 text-black hover:shadow-lg outline-none' placeholder='Search ChickChickGo or type URL' onChange={(e) => setText(e.target.value)} />
			{/* {!text && (
				<button type='button' className='absolute top-1.5 right-4 text-2xl text-gray-500' onClick={() => setText('')}>
					X
				</button>
			)} */}
			<Links />
		</div>
	)
}

export default Search
