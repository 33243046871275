import { createContext, useContext, useState } from 'react'

const ResultContext = createContext()
const baseUrl = 'https://google-search74.p.rapidapi.com/'

export const ResultContextProvider = ({ children }) => {
	const [results, setResults] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [searchTerm, setsearchTerm] = useState('')

	const getResults = async (type) => {
		setIsLoading(true)

		const response = await fetch(`${baseUrl}${type}`, {
			'method': 'GET',
			// 'content-type': 'application/json',
			'headers': {
				'X-RapidAPI-Key': 'f510619c58msh83c16e060744b4cp1940bfjsnf881fd6d4427',
				'X-RapidAPI-Host': 'google-search74.p.rapidapi.com',
			},
		})

		const data = await response.json()
		
		setResults(data)
		setIsLoading(false)
	}
	return <ResultContext.Provider value={{ getResults, results, searchTerm, setsearchTerm, isLoading }}>{children}</ResultContext.Provider>
}

export const useResultContext = () => useContext(ResultContext)
