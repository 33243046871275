import { Link } from 'react-router-dom'
import Search from './Search'

const Navbar = ({ darkTheme, setDarkTheme }) => {
	return (
		<div className='p-5 pb-0 flex flex-wrap sm:justify-between justify-center items-center border-b dark:border-gray-700 border-gray-200'>
			<div className='flex justify-between items-center space-x-5 w-screen'>
				<Link to='/'>
					<p className='text-2xl bg-orange-500 font-bold text-white py-1 px-2 rounded-xl shadow-md dark:bg-gray-500 dark:text-gray-900'>ChickChickGo 🐔 </p>
				</Link>
				<button type='button' onClick={() => setDarkTheme(!darkTheme)} className='text-lg dark:text-gray-900 border rounded-full px-2 py-1 hover:shadow-lg shadow dark:bg-gray-50 '>
					{darkTheme ? 'Light 💡' : 'Dark 🌛'}
				</button>
			</div>
			<Search />
		</div>
	)
}

export default Navbar
