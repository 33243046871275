import { CirclesWithBar } from 'react-loader-spinner'

const Loading = () => {
	return (
		<div className='flex justify-center items-center'>
			<CirclesWithBar color='#f97416' height={550} width={80} visible={true} wrapperStyle={{}} wrapperClass='' outerCircleColor='#f97416' innerCircleColor='#f97416' barColor='#f97416' ariaLabel='circles-with-bar-loading' />
		</div>
	)
}

export default Loading
